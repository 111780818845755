
import { Component, Vue, Watch } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import NewsAddNew from '@/views/NewsAddNew.vue';
import News from '@/models/News';
import NewsRepository from '@/repositories/NewsRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import moment from 'moment';
import { NewsReport } from '@/interfaces/components/news/NewsReport';
import { CmsNewsColumn } from '@/interfaces/components/news/CmsNewsColumn';
import CMSUser from '@/models/CMSUser';

@Component({
    name: 'NewsModuleCms',
    components: {
        NewsAddNew,
    },
})
export default class NewsModuleCms extends Vue {
    private activeNewsId: string | null = null;
    private isArchivedFilterOn: boolean = false;
    private searchQuery: string = '';
    private selectedRowKeys: string[] = [];
    private isNewsModalVisible: boolean = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private regexForStrippingHtml = /(<([^>]+)>)/gi;

    private get columns() {
        return [
            { title: this.$t('Title'), dataIndex: 'title', key: 'title' },
            { title: this.$t('News'), dataIndex: 'news', key: 'news' },
            { title: this.$t('Date created'), dataIndex: 'createdAt', key: 'createdAt' },
            { title: this.$t('Date updated'), dataIndex: 'updatedAt', key: 'updatedAt' },
        ];
    }

    private get activeNews(): CmsNewsColumn[] {
        const reports = NewsRepository.getAll() as NewsReport[];

        if (reports) {
            return reports.map((report: any) => {
                return {
                    key: report.id,
                    title: report.title,
                    news: report.content.replace(this.regexForStrippingHtml, '').substring(0, 50) + '...',
                    createdAt: moment(report.createdAt).format('DD.MM.YYYY'),
                    updatedAt: moment(report.updatedAt).format('DD.MM.YYYY'),
                };
            });
        }

        return [];
    }

    private get userRoles() {
        return this.$store.getters['jwtData/userRoles'];
    }

    private get isSuperAdmin() {
        return this.userRoles.includes('ROLE_SUPER_ADMIN');
    }

    private addNew() {
        if (!this.isSuperAdmin) {
            this.checkUserRightsForActivity('create');
            return;
        }

        this.isNewsModalVisible = true;
    }

    private onSelectChange(selectedRowKeys: any) {
        this.selectedRowKeys = selectedRowKeys;
    }

    private confirmArchivingOrActivating() {
        const action = this.isArchivedFilterOn ? 'activate' : 'archive';

        if (!this.isSuperAdmin) {
            this.checkUserRightsForActivity(action);
            return;
        }

        if (this.selectedRowKeys?.length === 0) {
            this.$notification.error({
                message: this.$t(`Error ${this.isArchivedFilterOn ? 'activating' : 'archiving'} report`) as string,
                description: this.$t(`Please select at least one report to ${action}`) as string,
            });

            return;
        }

        this.$confirm({
            title: `Are you sure you want to ${action} selected reports?`,
            content: '',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.archiveOrActivateReports();
            },
        });
    }

    private async archiveOrActivateReports() {
        this.loadingOverlay.start();

        if (!this.selectedRowKeys.length) {
            return;
        }

        // Delete reports locally and on server
        try {
            for (const reportId of this.selectedRowKeys) {
                await News.archiveOrActivateExisting(reportId, !this.isArchivedFilterOn);
            }
        } catch (error) {
            this.$notification.error({
                message: this.$t('Error ' + this.isArchivedFilterOn ? 'activating' : 'archiving' + ' report') as string,
                description: (error as Error).message,
            });

            return;
        } finally {
            this.loadingOverlay.stop();
        }

        // Reset selected reports
        this.selectedRowKeys = [];
        return Promise.resolve();
    }

    // Check is there are any selected rows and the user is authorized to delete reports
    private confirmDelete() {
        if (!this.isSuperAdmin) {
            this.checkUserRightsForActivity('delete');
            return;
        }

        if (this.selectedRowKeys?.length === 0) {
            this.$notification.error({
                message: this.$t('Error deleting report') as string,
                description: this.$t('Please select at least one report to delete') as string,
            });

            return;
        }

        this.$confirm({
            title: 'Are you sure you want to delete selected reports?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.deleteReports();
            },
        });
    }

    private async deleteReports() {
        this.loadingOverlay.start();

        if (!this.selectedRowKeys.length) {
            return;
        }

        // Delete reports locally and on server
        try {
            for (const reportId of this.selectedRowKeys) {
                await News.deleteExisting(reportId);
            }
        } catch (error) {
            this.$notification.error({
                message: this.$t('Error deleting report') as string,
                description: (error as Error).message,
            });

            return;
        } finally {
            this.loadingOverlay.stop();
        }

        this.$notification.success({
            message: this.$t('Success') as string,
            description: this.$t('Reports deleted successfully') as string,
        });

        // Reset selected reports
        this.selectedRowKeys = [];
        return Promise.resolve();
    }

    private openNewsReport(reportId: string) {
        this.activeNewsId = reportId;
        this.openPopup();
    }

    private createReport() {
        EventBus.$emit(EventBusEvents.createNewsReport);
    }

    private async fetchAllNewsReports() {
        this.loadingOverlay.start();

        try {
            await News.getAll();
        } catch (e) {
            Promise.reject(e);
        } finally {
            this.loadingOverlay.stop();
        }

        return Promise.resolve();
    }

    private async fetchArchivedNewsReports() {
        this.loadingOverlay.start();

        try {
            await News.getAllArchived();
        } catch (e) {
            Promise.reject(e);
        } finally {
            this.loadingOverlay.stop();
        }

        return Promise.resolve();
    }

    private checkUserRightsForActivity(activityType: string) {
        let message;
        let description;

        switch (activityType) {
            case 'create':
                message = this.$t('Error creating report');
                description = this.$t('You do not have permission to create a news report');
                break;
            case 'archive':
                message = this.$t('Error archiving report');
                description = this.$t('You do not have permission to archive a news report');
                break;
            case 'activate':
                message = this.$t('Error activating report');
                description = this.$t('You do not have permission to activate a news report');
                break;
            case 'delete':
                message = this.$t('Error deleting report');
                description = this.$t('You do not have permission to delete a news report');
        }

        this.$notification.error({
            message: message as string,
            description: description as string,
        });
    }

    private openPopup() {
        this.isNewsModalVisible = true;
    }

    private closePopup() {
        this.isNewsModalVisible = false;
        this.activeNewsId = null;
    }

    private async mounted() {
        this.fetchAllNewsReports();
        await CMSUser.getAllOnlyUsers();
    }

    @Watch('isArchivedFilterOn', { immediate: false })
    private switchActiveReports() {
        if (this.isArchivedFilterOn) {
            this.fetchArchivedNewsReports();
        } else {
            this.fetchAllNewsReports();
        }
    }
}
